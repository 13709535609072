/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import closeIcon from '../../assets/images/closeIcon.svg';

import validateEmail from '../../helpers/validateEmail';

import classes from './styles.module.scss';
import MeetingService from '../../services/MeetingService';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import RoundedInput from '../../components/Inputs/RoundedInput';
import Textarea from '../../components/Textarea';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';

export default function AssignSectionModal({
  show,
  handleClose,
  meetingId,
  createMeeting,
  folderId,
  meetingTitle,
  refetchSections,
  setMeetingId,
  setIsAssignSectionPopupVisible,
  setAssignSectionErrorMessage,
}) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const resetForm = () => {
    handleClose();
    setIsSubmitted(false);
    setEmail('');
    setMessage('');
  };

  const submitForm = async () => {
    try {
      let id = meetingId;

      if (!id) {
        const meeting = await createMeeting({ title: meetingTitle, folderId });
        id = meeting.id;
        setMeetingId(id);
        window.history.replaceState(null, 'SnapSOP', `/my-videos/${id}/edit`);
      }

      await MeetingService.assignSection({
        meetingId: id,
        email,
        message,
      });

      refetchSections();
      setIsSubmitted(true);
      setTimeout(() => {
        resetForm();
      }, 2500);
    } catch (error) {
      console.log(error);
      setAssignSectionErrorMessage(
        error?.response?.data?.message || 'Something went wrong'
      );
      setIsAssignSectionPopupVisible(true);
      handleClose();
    }
  };

  const emailChangedHandler = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.AssignSectionModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      {isSubmitted && (
        <div className={classes.successOverlay}>
          <div className={classes.popup}>
            <i className={classes.closeIcon} onClick={handleClose}>
              <img src={closeIcon} alt="Close" />
            </i>
            <b>Section Assigned!</b>
            <span>
              You will be notified when <br /> collaborator completes section(s)
            </span>
          </div>
        </div>
      )}
      <i
        className={classes.closeIcon}
        onClick={() => {
          handleClose();
          resetForm();
        }}
      >
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.form}>
        <h1>Invite other users to create content for your video</h1>
        <RoundedInput
          placeholder="Add email address"
          type="email"
          value={email}
          onChange={emailChangedHandler}
          setValue={setEmail}
          users
        />
        <div className={classes.messageContainer}>
          <Textarea
            placeholder="Add message..."
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />

          <EmojiPickerWithButton
            setCommentText={setMessage}
            buttonBottom="24px"
            buttonRight="24px"
            pickerTop="52px"
          />
        </div>

        <footer className={classes.footer}>
          <ConfirmButton
            isDisabled={!email.length || !validateEmail(email)}
            onClick={submitForm}
          />
        </footer>
      </div>
    </Modal>
  );
}
